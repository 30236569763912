.new_order_div_wrapper {
  background: #fafafa;
  width: 100%;
  height: 100%;
  min-height: 95vh;
}
.new_order_div_body {
  width: 40%;
  max-width: 568px;
  margin: auto;
}
.order_form {
  max-width: 568px;
}
.form-control {
  padding: 8px 8px !important;
}

.input-group .date_picker {
  padding: 4px 8px !important;
  border: 1px solid #828282 !important;
  border-radius: 8px !important;
  background-color: #fff !important;
  width: 100% !important;
}

@media only screen and (max-width: 525px) {
  .new_order_div_body {
    width: 95%;
  }
}
