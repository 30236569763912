body {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  min-width: 100%;
  max-width: 100%;
  overflow-x: hidden;

  border: none;
  box-sizing: border-box;
  font-family: "Helvetica", "Montserrat", "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  --background-primary: #001fcc;
  --color-primary: #001fcc;
  --background-light: #2c2c2c;
  --footer-background: #e6e8ec;
  --footer: #333333;
  --icon-color: #979da2;
  --selected-icon: #ffffff;
  --other-color: #a4c5fb;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

a {
  color: #1d1d1e;
}

.btn {
  border-radius: 10px !important;
}

.pt-5 {
  padding-top: 2rem !important;
}
.pt-6 {
  padding-top: 2.5rem !important;
}
.pt-7 {
  padding-top: 3rem !important;
}
.mb-5 {
  margin-bottom: 2rem !important;
}
.mb-6 {
  margin-bottom: 2.5rem !important;
}
.mb-7 {
  margin-bottom: 3rem !important;
}
.mt-5 {
  margin-top: 2rem !important;
}
.mt-6 {
  margin-top: 2.5rem !important;
}
.mt-7 {
  margin-top: 3rem !important;
}

.primary-background {
  background-color: #001fcc;
}
.primary-color {
  color: #001fcc;
}

.activeLink {
  color: #1d1d1e;
}
.disabledLink {
  color: red;
}

/* Order Status  */
.Complete {
  background-color: #e2f8f2 !important;
  color: #00d6c1 !important;
}
.Processing {
  /* background-color: #; */
  background-color: #c5b5d7 !important;
  color: #4e1f86 !important;
}
.Pending {
  background-color: #fef0e1 !important;
  color: #ff9400 !important;
}

/* Form Input and Label */
.form_input_border {
  /* border-color: #828282; */
  border: 1px solid #979da2;
  border-radius: 10px;
}

.form_input_label {
  color: #828282;
}

/* Form Error */
.formError {
  color: #bf1650 !important;
  margin-top: 2px;
}

.formError::before {
  display: inline;
  content: "⚠ ";
}

/* Button */
.basic_btn {
  display: inline-block;
  font-weight: 400;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  -webkit-user-select: none;
  user-select: none;
  border: 1px solid transparent;
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  line-height: 1.5;
  /* border-radius: 0.25rem; */
  color: #677080;
  background-color: transparent;
  background-image: none;
  /* border-color: #c0bfbf; */
}

.basic_btn:hover {
  border: 1px solid #202020 !important;
  cursor: pointer;
}

/* Table */

.table-responsive {
  /* box-shadow: 0px 6px 18px rgba(0, 0, 0, 0.06) !important; */
  background-color: white !important;
  border-radius: 10px;
}

.table-responsive table thead {
  background-color: #f6f4f9 !important;
  color: black;
  height: 50px;
  vertical-align: middle;
}

.main-table {
  margin-bottom: 0 !important;
}
.table > :not(:first-child) {
  border: none !important;
}

.main-table-body tr {
  height: 70px;
  vertical-align: middle;
  border-bottom: 1px solid #eeecf2 !important;
}

.main-table .main-table-header tr th {
  /* font-weight: 500; */
  font-size: 13px;
  line-height: 16px;
  letter-spacing: 0.01em;
  color: black;
}

/* Pagination */
.MuiPaginationItem-page.Mui-selected {
  background-color: white !important;
  border: 2px solid var(--color-primary);
  color: black !important;
  padding-bottom: 0px;
}

/* Dialog */
.generalInfoDialog {
  background: transparent;
  text-align: center;
  width: 80%;
  max-width: 465px;
  margin: auto;
  position: relative;
}

/* Error 404 */
.error-wrapper {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  transform: translate(-50%, -50%);
}
.error-wrapper .session-wrapper {
  overflow-x: hidden;
}
.error-body h2 {
  font-weight: 900;
  /* text-shadow: 9px 10px 1px rgba(0, 0, 0, 0.08); */
}
/* .oops {
  font-size: 3rem;
}
.bold {
  font-size: 10rem;
}
.error-msg {
  font-size: 4.375rem;
} */

@media only screen and (max-width: 525px) {
  .generalInfoDialog {
    width: 100%;
  }
}
