.order_home_wrapper {
  background: #fafafa;
  width: 100%;
  min-height: 100vh;
}
.order_div_body {
  width: 85%;
  margin: auto;
}

.home-search-input {
  max-width: 660px;
  width: 50%;
}
/* .home-search-input input::-webkit-outer-spin-button,
.home-search-input input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
.home-search-input input[type="number"] {
  -moz-appearance: textfield;
} */

.item_card {
  background: #ffffff;
  box-shadow: 0px 2px 80px rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  border: none;
}

@media only screen and (max-width: 525px) {
  .home-search-input {
    width: 100%;
  }
  .order_div_body {
    width: 95%;
  }
}
