/* Red and Blue Blob Images */
.loginTopLeftBlob {
  position: fixed;
  overflow: hidden;
  left: -3.93%;
  right: 66.01%;
  top: -21.11%;
  bottom: 67.62%;
  transform: rotate(15.32deg);
}
.loginBtmRightBlob {
  position: fixed;
  overflow: hidden;
  right: -5%;
  bottom: -2.26%;
  transform: rotate(15deg);
}

/* Main */
.login-wrapper {
  min-height: 100vh;
  max-height: 100vh;
}
.login-logoImg {
  width: auto;
  max-width: 375px;
}

.login-div {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 80%;
  max-width: 575px;
}

@media only screen and (max-width: 525px) {
  .login-logoImg {
    width: 60%;
  }
  .login-div {
    width: 85%;
  }
  .login-btn {
    width: 100% !important;
  }
}
